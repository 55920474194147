import Container from "@/Components/Backend/Grid/Container";
import Row from "@/Components/Backend/Grid/Row";
import Column from "@/Components/Backend/Grid/Column";
import { Icon } from "@iconify/react/dist/iconify.js";
import Text from "../../../Text";
import ClassicButton from "../../../Buttons/ClassicButton";
import { EstatesCarouselProps } from "@/types/components/front/blocks/estatesCarousel";
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";

import "swiper/css/navigation";
import "swiper/css";
import WebpPicture from "@/Components/WebpPicture";

const Carousel = ({ slides }: EstatesCarouselProps) => {
    const swiperRef = useRef<SwiperRef>(null);
    // const [height, setHeight] = useState(0);
    const [slideIndex, setSlideIndex] = useState(1);

    const handlePrev = useCallback(() => {
        if (!swiperRef.current) return;
        swiperRef.current.swiper.slidePrev();

        updateNavPosition();
    }, []);

    const handleNext = useCallback(() => {
        if (!swiperRef.current) return;
        swiperRef.current.swiper.slideNext();

        updateNavPosition();
    }, []);

    const updateNavPosition = () => {
        const swiper = swiperRef.current?.swiper;
        const slideActive = swiper?.slides[swiper.activeIndex];
        // const slideActiveImg = slideActive?.querySelector("picture > img");

        if (swiper) {
            setSlideIndex(swiper.realIndex + 1);
        }

        // if (slideActiveImg && window.innerWidth > 600) {
        //     setHeight(slideActiveImg.clientHeight);
        // }
    };

    const activeSlides = slides.filter((slide) => slide.status == "1");
    activeSlides.sort((a, b) => {
        if (a.name === "Orion") {
            return -1;
        }
        if (b.name === "Orion") {
            return 1;
        }
        return 0;
    });
    
    return (
        <Container>
            <div className="relative" id="slider-test">
                <Swiper
                    ref={swiperRef}
                    modules={[Navigation, Pagination]}
                    loop={true}
                    centeredSlides={true}
                    autoHeight={true}
                    slidesPerView={1}
                    onResize={updateNavPosition}
                    // onInit={updateNavPosition}
                    onSlideChange={updateNavPosition}
                    onUpdate={updateNavPosition}
                    observer={true}
                    observeParents={true}
                    pagination={{
                        el: '.swiper-custom-pagination',
                        type: 'bullets'
                    }}
                >
                    {activeSlides.map((slide, index) => (
                        <SwiperSlide key={index}>
                            <Row className="sm:gap-0 gap-4 xl:items-end">
                                <Column
                                    xl={8}
                                    lg={7}
                                    md={6}
                                    className="picture-col xl:h-full"
                                >
                                    {/* Estate Main Photo */}
                                    <WebpPicture
                                        src={`/storage/estates/${slide.estate_photo}`}
                                        className="w-full xl:h-[576px] lg:h-[430px] md:h-[464px] h-auto object-cover rounded-[4px]"
                                        alt={slide.name}
                                        lazyLoad={false}
                                    />
                                </Column>
                                <Column
                                    xl={4}
                                    lg={5}
                                    md={6}
                                    className="flex items-end"
                                >
                                    <div className="md:py-0 md:px-8 sm:py-3 sm:px-4">
                                        <Text
                                            color="i-gray"
                                            heading="estateCarousel"
                                            className="mb-6"
                                        >
                                            <p>Osiedle Idea {slide.name}</p>
                                        </Text>
                                        <Text>
                                            <article>
                                                {slide.desc}
                                            </article>
                                        </Text>
                                        <ClassicButton
                                            linkProps={{
                                                href: route(
                                                    "front.discover-estates.single",
                                                    slide.slug
                                                )
                                            }}
                                            className="mt-8"
                                            full
                                        >
                                            <Text
                                                heading="label"
                                                bold
                                                color="white"
                                            >
                                                Poznaj Osiedle Idea {slide.name}
                                            </Text>
                                            <Icon
                                                icon="material-symbols:arrow-right-alt-rounded"
                                                width={16}
                                                height={16}
                                            />
                                        </ClassicButton>
                                    </div>
                                </Column>
                            </Row>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div
                    className="controls static left-0 bottom-[height] z-10 pt-4 transition-[top] ease flex md:gap-x-2 justify-center"
                >
                    <div className="flex md:gap-y-0 gap-y-4 flex-wrap sm:gap-x-4 gap-x-2 sm:w-auto w-full justify-center md:-mr-[15px]">
                        <button
                            id="prev"
                            onClick={handlePrev}
                            className="flex md:w-auto w-full items-center text-i-navy font-bold [&:hover_.btn-icon]:text-i-navy"
                            title="Poprzedni slajd"
                        >
                            <div className="border-[2px] border-i-indigo py-3 px-4 md:min-w-[160px] min-w-full justify-center text-center flex items-center gap-x-2">
                                <Icon
                                    icon="ic:round-arrow-back-ios"
                                    width={24}
                                    height={24}
                                />
                                poprzednie
                            </div>
                        </button>
                        <div className="self-center md:w-auto w-full">
                            <p className="flex gap-x-1 items-center justify-center text-i-gray font-medium text-20">
                                <span className="font-bold text-i-indigo">{slideIndex}</span>
                                <span>/</span>
                                <span>{activeSlides.length}</span>
                            </p>
                        </div>
                        <button
                            id="next"
                            onClick={handleNext}
                            className="flex items-center text-i-navy font-bold [&:hover_.btn-icon]:text-i-navy md:w-auto w-full"
                            title="Kolejny slajd"
                        >
                            <div className="border-[2px] border-i-indigo py-3 px-4 md:min-w-[150px] min-w-full justify-center text-center flex items-center gap-x-2">
                                następne
                                <Icon
                                    icon="ic:round-arrow-forward-ios"
                                    width={24}
                                    height={24}
                                />
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default Carousel;
